.item-type-category-label {
  display: inline-block;
  vertical-align: middle;
  padding: .25em .75em;
  background-color: $cloud-light;
  border-radius: $border-radius;

  .item-type-category-label-icon {
    display: inline-block;
    margin-right: .25em;
  }
}
