/******
    The Item Finder Form element
******/
@include min-screen('desktop') {
  .item-finder .form {
    display: flex;
    align-items: center;

    .form-content {
      flex-grow: 1;
      margin-right: $columns-gutter;

      .field {
        margin-bottom: 0;
      }
    }
  }
}


/******
    Items List Styling
    ----
    Remove the default style of anchor element
    when used as item-wrapper
******/
a.item-wrapper {
  display: block;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;

  @include hover {
    &:hover {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }
}


/******
    Style single item
******/
.card.item-card {
  // Remove some of the card style, only in mobile screen
  @include max-screen('phone') {
    overflow: hidden;
    padding: 0;
  }


  // ----
  //  Style the Image Element
  // ----
  .item-picture {
    display: block;
    width: 100%;
    height: 10em;
    background-color: $white-shade-soft;
    overflow: hidden;
    position: relative;

    > .image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }


  // ----
  //  Style the Item Descriptor Wrapper
  // ----
  .item-description {
    display: flex;
    flex-direction: column;
    padding: 1.5em;

    // Change the Style of the inner Header
    .item__header {
      color: inherit;

      .header .header-content {
        color: $primary;
        font-size: 1.35em;
      }
    }
  }


  // ----
  //  Style item Properties
  // ----
  .item-properties {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    white-space: pre;

    p {
      margin-right: 1em;
    }
  }


  // ----
  //  Change Item direction on tablet up
  // ----
  @include min-screen('tablet') {
    .item-content {
      display: flex;

      // On tablet, change the item picture size
      // and add border and corner radius
      .item-picture {
        border: solid 1px $cloud-light;
        border-radius: $border-radius-big;
        width: 10em;
        height: 7.5em;
        flex-shrink: 0;
        margin-right: 1.25em;
      }

      // On tablet, remove the content padding
      .item-description {
        padding: 0;
        flex-grow: 1;
      }

    }
  }
}
