.images-preview-container {
  display: flex;
  flex-wrap: wrap;
}

.images-preview-wrapper {
  padding: 1em;
  background-color: $white-shade-soft;
  border-radius: $border-radius;
  border: solid 1px $cloud-light;
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
  z-index: 10000;

  &:last-child {
    margin-right: 0;
  }

  > div {
    height: 128px;
    width: 128px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  > button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
    transform: translateY(-50%);

    &.images-sortable-handle {
      right: auto;
      left: 0;
    }
  }
}
