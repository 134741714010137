@include min-screen('tablet') {
  .login-content {
    display: grid;
    align-items: inherit;
    .login-content--group {
      justify-content: space-between;
    }
  }
}

.login-content {
  border: solid #cbccce 1px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-content--group {
    display: flex;
    justify-content: center;
    padding: 2em 0;
    flex-wrap: wrap;
  }

  .login-content--link {
    margin-bottom: 2em;
  }

  .login-content--img {
    max-width: 100%;
    height: auto;
    justify-self: center;
  }
}