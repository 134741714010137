.view-content {
  padding: 0 0 3em;
  margin-top: 3em;
}

@include max-screen('phone') {
  body.with-navbar {
    padding-top: 56px;
  }
}
