@include exact-screen('phone') {
  .container:not(.fluid) {
    padding-left: 1em;
    padding-right: 1em;
  }
}


@include min-screen('desktop') {
  .on-desktop-is-auto {
    min-width: auto;
    width: auto;
    flex: 0 0 auto;
  }
}


.input.field {
  .content {
    &.icon-on-left {
      > input, > textarea {
        padding-left: $input-horizontal-padding * 1.5 + $icon-width;
      }
    }
  }
}


input {
  @include input-placeholder {
    opacity: .5;
  }
}
