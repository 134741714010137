.card {
  border: solid 1px $cloud-light;
  border-radius: $border-radius-big;
  padding: 1.5em;
  background-color: $white;
  box-sizing: initial;
  position: relative;
  @include smoothShadow(4);
  margin-bottom: 1.5em;


  .card-badge {
    position: absolute;
    display: block;
    z-index: 10;
    background-color: $cloud-light;
    top: 2em;
    left: -.5em;
    border-radius: .5em;
    padding: .5em 1em;
    font-weight: $semi-bold;
    @include smoothShadow(4);

    &.is-primary {
      background-color: $primary;
      color: $white;
    }

    &.is-warning {
      background-color: $warning;
      color: $white;
    }

    &.is-danger {
      background-color: $danger;
      color: $white;
    }

    &.is-success {
      background-color: $success;
      color: $white;
    }

    &.is-info {
      background-color: $info;
      color: $white;
    }
  }
}
