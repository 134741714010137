@use 'sass:map';

// ----
//  Core Configuration Variable Overrides
//  --
//  Insert here are variable that must override global core settings
// ----

// ----
//  Inject and Use Custom Font
// ----
$border-radius-huge: 2em;
$columns-gutter: 1rem;

$default-web-font: 'Poppins';
$default-monospace-font: 'Source Code Pro';


// ----
//  Import Initial Configuration Files
// ----
@import '~@appbuckets/react-ui/styles/config-core';
@import '~@appbuckets/react-ui/styles/_core/functions/all';

// ----
//  Color Variables Override
//  --
//  Insert here all color variable that must override the core default
// ----
$red: #f25a5a;
$yellow: #fdc441;
$green: #49aa8b;
$blue: #3b82f6;
$purple: #cb5089;
$pink: #ec4899;
$orange: $yellow;

$cloud-dark: #4b5563;
$cloud: #d1d5db;
$cloud-light: #e5e7eb;


// $primary: #fe725d;
$primary: $purple;

$white-shade: #f2f4f8;
$white-shade-soft: transparentize($white-shade, .35);

$body-background: #ffffff;

// ----
//  Colors Import
// ----
@import '~@appbuckets/react-ui/styles/_core/colors';
@import '~@appbuckets/react-ui/styles/_core/typography';
@import '~@appbuckets/react-ui/styles/_core/variables';


// ----
//  Variable Override per Elements
//  --
//  Insert here all elements variable
// ----

$container-padding: 3em;

$divider-color: $cloud-light;

$fluid-container-padding-on-tablet: 1em;

$header-font-weight: $bold;

$input-background-color: $white-shade-soft;
$input-vertical-padding: 12px;
$input-horizontal-padding: 1em;
$input-border-radius: $border-radius-small;

$item-solid-background-color: $white-shade-soft;
