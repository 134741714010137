.item__header {
  display: flex;
  justify-content: space-between;

  .item__header--date {
    font-weight: $semi-bold;
    color: $text-secondary;
    font-size: .65em;
    margin-bottom: .5em;
  }

  .item__header--link {
    display: inline-block;
    color: $link-color;
  }

  .item__header--back-btn {
    margin-bottom: 1em;
  }

  .item__header--id {
    font-weight: $semi-bold;
  }

  h3 {
    font-weight: $bold;
    margin-bottom: 0;
  }

  .item__header--categories {
    display: block;
    margin: .5em 0 1em;
  }
}
