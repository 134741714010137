.hero-selector {
  text-align: center;
  background-color: $white-shade-soft;
  height: 100%;
  padding: 1.5em;
  border-radius: $border-radius-big;
  transition: background-color $transition-ease $transition-duration, color $transition-ease $transition-duration;
  cursor: pointer;


  // ----
  //  Style the Icon
  // ----
  .icon {
    margin: 0;
    color: $cloud-dark;
    transition: color $transition-ease $transition-duration;
  }


  // ----
  //  Fix Header Style
  // ----
  .header {
    margin-bottom: 0;
  }


  // ----
  //  Active Style
  // ----
  &.active {
    color: $white;
    background-color: $primary;

    .icon {
      color: $white;
    }
  }


  // ----
  //  Hover Style
  // ----
  @include hover {
    &:hover:not(.active) {
      background-color: $white-shade;

      .icon {
        color: $primary;
      }
    }
  }
}
