.product-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @include min-screen('desktop') {
    flex-direction: row;
  }
}

.product-gallery {
  flex: 1;
  width: 100%;

  @include min-screen('desktop') {
    max-width: 60%;
  }
}

.product-content {
  flex: 1;
  padding: 1rem;

  @include min-screen('desktop') {
    padding: 0 1rem;
  }
}

.embla {
  position: relative;
  max-width: 100%;
  margin-bottom: 1rem;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  border-radius: $border-radius-big;

  @include min-screen('tablet') {
    border-radius: $border-radius-huge;
  }
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  margin-left: 0;
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  padding: 0;
  position: relative;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 300px;
  border-radius: $border-radius-big;

  @include min-screen('tablet') {
    height: 400px;
    border-radius: $border-radius-huge;
  }

  @include min-screen('desktop') {
    height: 500px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

.embla--thumb {
  padding-top: 1rem;
  margin-bottom: 0;
}

.embla__viewport--thumb {
  border-radius: 0 !important;
}

.embla__container--thumb {
  display: flex;
  gap: 0.5rem;
  margin: 0;
  padding: 0 0.5rem;
  cursor: default;
}

.embla__slide--thumb {
  flex: 0 0 auto;
  width: 100px;
  padding: 0;

  &.is-selected .embla__slide__inner--thumb {
    border: 2px solid #3483fa;
  }
}

.embla__slide__inner--thumb {
  height: 100px !important;
  width: 100px;
  border-radius: $border-radius !important;
  overflow: hidden;
  cursor: pointer;
  touch-action: manipulation;
  border: 2px solid transparent;
  transition: border-color 0.2s;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  &:hover > img {
    opacity: 0.75;
  }
}

.embla__slide--thumb.is-selected .embla__slide__inner--thumb >r img {
  opacity: 1;
}
