.showcase-button {
  cursor: pointer;
  border-radius: $border-radius-huge;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include smoothShadow(0);
  transition: box-shadow $transition-duration $transition-ease;

  @include hover {
    &:hover {
      @include smoothShadow(4);
    }
  }

  > div {
    flex-grow: 0 !important;
  }
}
