/**
 * Project Yard4
 *
 * _FullPageLoader @ src/assets/styles/elements/_full-page-loader.scss
 *
 * Defined at 28 mag 2020
 * Copyright Marco Cavanna • 2020
 *
 * ---
 * Full Page Loader is visible only before app start
 *
 */

.full-page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-items: center;
  background-color: $primary;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
