.navbar-content {

  // ----
  //  Style the Desktop navbar
  // ----
  &.no-phone {
    padding: $view-padding 1em;
    border-bottom: solid 1px $cloud-light;

    /// Set the Action Buttons Position
    > .app-actions {
      margin-left: auto;
    }
  }


  // ----
  //  Style the Phone AppBar
  // ----
  &.phone {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    height: 56px;
    width: 100%;
    background-color: $white-shade;
    border-bottom: solid 1px $cloud-light;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em;

    a {
      color: inherit;
      text-decoration: none;
    }

    .navbar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: $semi-bold;

      .icon {
        margin: 0 0 .25em;
      }
    }
  }


  // ----
  //  Hide Desktop navbar on Phone
  // ----
  @include max-screen('phone') {
    &.phone {
      display: flex;
    }

    &.no-phone {
      display: none;
    }
  }


  // ----
  //  Hide Phone navbar on Phone Up
  // ----
  @include min-screen('tablet') {
    &.phone {
      display: none;
    }

    &.no-phone {
      display: flex;
    }
  }

}
